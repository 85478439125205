import { useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import ProductContext from "../context/ProductContext";

import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdb-react-ui-kit";
import axios from "axios";
import { formatter } from "./Products";

const ProductDetails = () => {
    const { product, setProduct, basket } = useContext(ProductContext);


    const { id } = useParams();

    console.log("basket1", basket);
    const query = `query($id: ID){
                            item(where: {id: $id}) {
                            id
                            itemName
                            itemDescription
                            itemPrice
                            asset
                            createdAt
                        }
                    }
                    `;

    const variables = {
        id: id,
    };

    useEffect(() => {
        async function getAllProducts() {
            axios.request({
                url: `https://api-ap-south-1.hygraph.com/v2/clgxb471x0nfi01umgtqydo68/master`,
                method: 'POST',
                data: {
                    query,
                    variables
                }
            }).then((res) => {
                setProduct(res.data.data.item)
                // console.log(res.data)
            })
        }
        getAllProducts()
        // setProduct(productsApi.find((el) => el.id === parseInt(id)));
    }, [id, setProduct]);

    // console.log("product", product);

    return (
        <MDBContainer id="productDetails" className="mb-5">
            <Link to={"/"}><MDBIcon fas icon="home" /></Link>
            <MDBRow className="">
                <MDBCol size="12" lg="4" id="firstCol">
                    <p>Product Details:</p>
                    <p>ID: {product?.id} </p>
                    <hr />
                    <img style={{ maxWidth: "300px", maxHeight: "300px" }} src={product?.asset?.imageURL} alt="product" />
                    <hr />
                </MDBCol>
                <MDBCol className="d-flex align-items-center" size="12" lg="4" id="secondCol">
                    <h3 className="fw-400">{product?.itemName}</h3>
                    <strong style={{
                        fontSize: "32px",
                    }}>{formatter.format(product?.itemPrice)}</strong>
                </MDBCol>
                <MDBCol className="d-flex align-items-center" size="12" lg="4" id="thirdCol">
                    <p id="overview">{product?.itemDescription} </p>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );
};

export default ProductDetails;
