import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import img1 from '../images/20200729_124525.jpg'
import img2 from '../images/20200729_124953.jpg'
import img3 from '../images/20200729_125035.jpg'
import img4 from '../images/20200729_130156.jpg'
import img5 from '../images/20200729_125226.jpg'
import img6 from '../images/20200729_125616.jpg'
import img7 from '../images/20200729_125932.jpg'


const Carouselcmp = () => {
    return (
        <div>
            <Carousel style={{
                marginTop:"30px",
            }}>
                <Carousel.Item>
                    <img
                        className="d-block w-100 crsl-hgt"
                        src={img2}
                        alt="First slide"
                    />
                    {/* <Carousel.Caption>
                        <h3>First slide label</h3>
                        <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                    </Carousel.Caption> */}
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100 crsl-hgt"
                        src={img1}
                        alt="Second slide"
                    />

                    {/* <Carousel.Caption>
                        <h3>Second slide label</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    </Carousel.Caption> */}
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100 crsl-hgt"
                        src={img3}
                        alt="Third slide"
                    />

                    {/* <Carousel.Caption>
                        <h3>Third slide label</h3>
                        <p>
                            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
                        </p>
                    </Carousel.Caption> */}
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100 crsl-hgt"
                        src={img4}
                        alt="fourth slide"
                    />

                    {/* <Carousel.Caption>
                        <h3>Third slide label</h3>
                        <p>
                            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
                        </p>
                    </Carousel.Caption> */}
                </Carousel.Item>
                {/* <Carousel.Item>
                    <img
                        className="d-block w-100 crsl-hgt"
                        src={img5}
                        alt="Fifth slide"
                    />

                    <Carousel.Caption>
                        <h3>Third slide label</h3>
                        <p>
                            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
                        </p>
                    </Carousel.Caption>
                </Carousel.Item> */}
                <Carousel.Item>
                    <img
                        className="d-block w-100 crsl-hgt"
                        src={img6}
                        alt="sixth slide"
                    />

                    {/* <Carousel.Caption>
                        <h3>Third slide label</h3>
                        <p>
                            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
                        </p>
                    </Carousel.Caption> */}
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100 crsl-hgt"
                        src={img7}
                        alt="seventh slide"
                    />

                    {/* <Carousel.Caption>
                        <h3>Third slide label</h3>
                        <p>
                            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
                        </p>
                    </Carousel.Caption> */}
                </Carousel.Item>
            </Carousel>
        </div>
    )
}

export default Carouselcmp
