import React from 'react'
import { Link } from 'react-router-dom'
import { MDBIcon } from 'mdb-react-ui-kit'
import img1 from '../images/fotor_2023-4-27_1_21_43.png'
import img2 from '../images/indaneoillogo.png'

const Footer = () => {
    return (
        <div>
            <footer style={{
                backgroundColor: "#4F200D",
                borderRadius: "10px",
                width: "100%",
            }} className="text-white">
                <div className="container">
                    <nav className="row">
                        <ul style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                        }} className="col-4 col-md3">
                            <li style={{
                                marginTop: "25px"
                            }} className='lst-none'>
                                <Link to={'/'} className="col-4 col-md3">
                                    <img style={{ width: "150px" }} src={img1} alt="image of Founder" className="img-responsive mx-2 dp-sdm" />
                                </Link>
                            </li>
                            {/* <li style={{
                                marginTop:"10px"
                            }} className="col-4 col-md3 lst-none">
                                <p>Sourav DasMohanta</p>
                            </li> */}
                            <li style={{
                                marginTop: "10px",
                                display:"flex",
                                justifyContent:"center"
                            }} className="col-4 col-md3 lst-none sdm-dsg">
                                <p>Partner</p>
                            </li>
                        </ul>
                        <ul style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                        }} className="lst-none col-4 col-md3">
                            <li className='kdi-dtls'>Kultikri Debasis Indane Gramin Vitrak</li>
                            <li className='kdi-dtls'>Address:- Kultikri, Jhargram</li>
                            <li className='kdi-dtls'>Mob No:- 9733344427</li>
                            <li className="hdn-logo"><img src={img2} alt="indane oil logo" /></li>
                        </ul>
                        <ul style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                        }} className="lst-none col-4 col-md3 cntct-logo">
                            <li className="lnksft"> <a href="https://www.facebook.com/KultikriDebasisIndaneGraminVitrak?mibextid=ZbWKwL" target="_blank"><MDBIcon style={{
                                padding: "15px"
                            }} size="lg" fa icon="facebook" /></a> </li>
                            <li className="lnksft"> <a href="tel:+919733344427"><MDBIcon style={{
                                padding: "15px"
                            }} size="lg" fa icon="phone-volume" /></a> </li>
                            <li className="lnksft"><a href="mailto:kultikri.indane@gmail.com" target='_blank'><MDBIcon style={{
                                padding: "15px"
                            }} size="lg" fa icon="envelope" /></a></li>
                            <li className="lnksft"><a href="https://wa.me/919733344427" target="_blank"><MDBIcon style={{
                                padding: "15px"
                            }} size="lg" fa icon="whatsapp" /></a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </footer>
        </div>
    )
}

export default Footer
