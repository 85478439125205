import "./App.css";
import { MDBCol, MDBRow, MDBContainer } from "mdb-react-ui-kit";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NavBar from "./components/NavBar";
import Products from "./components/Products";
import ProductDetails from "./components/ProductDetails";
import { useEffect, useState } from "react";
import axios from "axios";
import Accessories from "./components/Accessories";
import Footer from "./components/Footer";

function App() {
  const [items, setItems] = useState([]);
  const [accessories, setAccessories] = useState([]);

  useEffect(() => {
    async function getAllProducts() {
      await axios.request({
        url: "https://api-ap-south-1.hygraph.com/v2/clgxb471x0nfi01umgtqydo68/master",
        method: "POST",
        data: {
          query: `{items(first: 100){
            id
            itemDescription
            itemName
            publishedAt
            itemPrice
            asset
          }}`,
        },
      }).then((res)=>setItems(res.data.data.items))
    }
    getAllProducts()
  }, []);
  useEffect(()=>{
    async function getAllAccessories() {
      await axios.request({
        url: "https://api-ap-south-1.hygraph.com/v2/clgxb471x0nfi01umgtqydo68/master",
        method: "POST",
        data: {
          query: `{accessories(first: 100) {
            id
            publishedAt
            accessoriesDescription
            accessoriesName
            accessoriesPrice
            accessoriesAsset
          }}`,
        },
      }).then((res)=>setAccessories(res.data.data.accessories))
    }
    getAllAccessories()
  }, []);
  return (
    <MDBContainer
      style={{
        paddingTop: "10px",
      }}
    >
      <MDBRow
        style={{
          marginTop: "60px",
        }}
      >
        <Router>
          <MDBCol>
            <NavBar />
          </MDBCol>
          <Routes>
            <Route path="/" element={<Products items = {items}/>} />
            <Route path="/product/:id" element={<ProductDetails />} />
          </Routes>
            <Accessories accessories = {accessories}/>
            <Footer />
        </Router>
      </MDBRow>
    </MDBContainer>
  );
}

export default App;
