import React from 'react'
import {MDBContainer, MDBNavbarNav, MDBRow, MDBCol, MDBNavbarItem, MDBNavbar, MDBIcon} from 'mdb-react-ui-kit'
import { Link } from 'react-router-dom'
import Contact from './Contact'
import CntctDtls from './CntctDtls'
import img1 from '../images/indaneoillogo.png'

const NavBar = () => {
    return (
        <div>
            <MDBNavbar expand="lg" light bgColor='light' className="mb-3 fixed-top">
                <MDBContainer fluid>
                    <MDBNavbarNav fullWidth={false} className="d-flex flex-row align-items-center justify-content-center">
                        <MDBNavbarItem className='me-3 me-lg-2 logo-sec'>
                            {/* <Link> */}
                                {/* <MDBIcon size="lg" spin fas icon="fire-burner"/> */}
                                <img className='logo-sec' style={{
                                    width:"150px",
                                    margin:"0"
                                }} src={img1} alt="Indane Oil Logo" />
                            {/* </Link> */}
                        </MDBNavbarItem>
                    </MDBNavbarNav>
                    <MDBNavbarNav fullWidth={false} className="d-flex flex-row">
                        <MDBNavbarItem className='me-3 me-lg-2'>
                            <Link to={'/'}>
                                <h3 className="orange-cl brand-name">KULTIKRI DEBASIS INDANE <br /> <p style={{
                                    fontSize:"20px",
                                    fontWeight:"200",
                                    textAlign:"center",
                                    marginBottom:"0"
                                }}>An Indian Oil LPG Distributor</p></h3>
                            </Link>
                        </MDBNavbarItem>
                    </MDBNavbarNav>
                    <MDBNavbarNav right fullWidth={false} className='d-flex flex-row'>
                        <MDBNavbarItem>
                            {/* <Link> */}
                                <Contact />
                                <CntctDtls />
                            {/* </Link> */}
                        </MDBNavbarItem>
                    </MDBNavbarNav>
                </MDBContainer>
            </MDBNavbar>
        </div>
    )
}

export default NavBar
