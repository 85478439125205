import { useContext } from "react";

import ProductContext from "../context/ProductContext";
import { MDBRow, MDBCol, MDBRipple } from "mdb-react-ui-kit";

import { Link } from "react-router-dom";
// import Footer from "./Footer";
import { truncate } from "../utils/truncate";


export const formatter = new Intl.NumberFormat('en-US', {
    style: "currency",
    currency: "inr",
})

const Accessories = ({ accessories }) => {
    const { searchTerm } = useContext(ProductContext);
    return (
        <>
            <p style={{
                // textAlign:"center",
                fontWeight: "700",
                fontSize: "20px"
            }}>Accessories:</p>
            <MDBRow style={{}} id="products" className="mb-5 d-flex align-items-center justify-content-center">
                {accessories
                    ?.filter((val) => {
                        if (searchTerm === "") {
                            return val;
                        } else if (val.accessoriesOverview.toLowerCase().includes(searchTerm.toLowerCase())) {
                            return val;
                        }
                        // return console.log("mmm")
                        return false;
                    })
                    ?.map((x, i) => (
                        <MDBCol size="6" md="4" lg="3" key={i} className="card  shadow-9 m-2 g-3 d-flex align-items-center justify-content-center">
                            <MDBRipple
                                rippleColor="dark"
                                rippleTag="div"
                                className="bg-image hover-zoom"
                            >
                                <img style={{ width: "100%", padding: "20px" }} src={x?.accessoriesAsset?.imageURL} className="card-img-top  " alt="a product" />
                                {/* <Link to={`product/${x?.id}`}> */}
                                {/* </Link> */}
                            </MDBRipple>

                            <div className="card-body me-auto crsr-pntr">
                                {/* <Link to={`product/${x?.id}`}> */}
                                <p id="cardTitle" className="card-title ">
                                    {truncate(x?.accessoriesName, 25)}
                                </p>
                                {/* </Link> */}
                                <strong>{formatter.format(x?.accessoriesPrice)}</strong>
                            </div>
                        </MDBCol>
                    ))}
            </MDBRow>
            <hr style={{
            }} />
        </>
    )
}

export default Accessories
