import { useState } from 'react';
import Button from 'react-bootstrap/Button';
// import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

const CntctDtls = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <Button className='cntctus' variant="primary" onClick={handleShow}>
                Contact Us
            </Button>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Contact Us</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span><h5>Name:- Sourav Dasmahanta</h5><h6>(Partner & Authorised Signatory)</h6>Mobile No:- 7001700270/9733344427 <br />Email ID:- souravdasmahanta@Kultikridebasisindane.com</span>
                    <br />
                    <br />
                    <span><h5>Name:- Sattapriya Chakraborty </h5><h6>Genaral Manager (Sales & Marketing)</h6>Mobile No:- 8617312565 <br />Email ID:- sales.kdigv@kultikridebasisindane.com</span>
                    <br />
                    <br />
                    <span><h5>Name:- Sanju Bera </h5><h6>Manager (Accounts)</h6>Mobile No:- 9733003320 <br />Email ID:- kultikari.indane@gmail.com </span>
                    <br />
                    <br />
                    <span><h5>Help desk </h5>Mobile No:- 8159090413 <br />Email ID:- kultikari.indane@gmail.com</span>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default CntctDtls
