import { useState } from 'react';
import Button from 'react-bootstrap/Button';
// import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

const CntctDtls = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <Button className='cntctus' variant="primary" onClick={handleShow}>
                Refill Price
            </Button>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Refill Price List</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span>2 kg ftl 230/-</span>
                    <br />
                    <span>5 kg Domestic 343/-</span>
                    <br />
                    <span>5 kg FTL Non-Domestic - 520/- </span>
                    <br />
                    <span>5kg  composite - 343/-</span>
                    <br />
                    <span>5kg FTL composite non-domestic 520/-</span>
                    <br />
                    <span>10 kg composite - 666.50/-</span>
                    <br />
                    <span>14.2 kg Domestic - 921.50/-</span>
                    <br />
                    <span>19 kg normal- 1800.00/-</span>
                    <br />
                    <span>19 kg xtratej- 1822.50/-</span>
                    <br />
                    <span>19 kg nanocut - 2057.50/-</span>
                    <br />
                    <span>47.5 kg- 4495.00/-</span>
                    <br />
                    <span>47.5 kg xtratej - 4551.00</span>
                    <br />
                    <span>425kg jumbo non-domestic - 40208.00 </span>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default CntctDtls
