import { useContext } from "react";

import ProductContext from "../context/ProductContext";
import { MDBRow, MDBCol, MDBRipple } from "mdb-react-ui-kit";

import { Link } from "react-router-dom";
// import SearchBar from "./SearchBar";
import Carouselcmp from "./Carouselcmp";
// import Footer from "./Footer";
import RefillList from "./RefillList"
import { truncate } from "../utils/truncate";
// import img1 from "../images/20200729_124525.jpg"
// import Accessories from "./Accessories";

export const formatter = new Intl.NumberFormat('en-US',{
    style: "currency",
    currency: "inr",
})
const Products = ({items}) => {
    const { searchTerm } = useContext(ProductContext);
    
    
    return(
        <>
        <Carouselcmp />
        {/* <SearchBar /> */}
        <hr style={{
            marginTop:"15px"
        }}/>
        <p style={{
            textAlign:"center",
            fontWeight: "700",
            fontSize: "30px"
        }}>Products</p>
        <hr />
        <p style={{
            // textAlign:"center",
            fontWeight: "700",
            fontSize: "20px"
        }}>New Connection:</p>
        <MDBRow style={{ minHeight: "100vh" }} id="products" className="mb-5 d-flex align-items-center justify-content-center">
            {items
                ?.filter((val) => {
                    if (searchTerm === "") {
                        return val;
                    } else if (val.itemOverview.toLowerCase().includes(searchTerm.toLowerCase())) {
                        return val;
                    }
                    // return console.log("mmm")
                    return false;
                })
                ?.map((x, i) => (
                    <MDBCol size="6" md="4" lg="3" key={i} className="card  shadow-9 m-2 g-3 d-flex align-items-center justify-content-center">
                        <MDBRipple
                            rippleColor="dark"
                            rippleTag="div"
                            className="bg-image hover-zoom"
                        >
                            <img style={{ width: "100%", padding: "20px" }} src={x?.asset?.imageURL} className="card-img-top  " alt="a product" />
                            <Link to={`product/${x?.id}`}>
                            </Link>
                        </MDBRipple>

                        <div  className="card-body me-auto">
                            <Link to={`product/${x?.id}`}>
                                <p id="cardTitle" className="card-title ">
                                    {truncate(x?.itemName, 25)}
                                </p>
                            </Link>
                            <strong>{formatter.format(x?.itemPrice)}</strong>
                        </div>
                    </MDBCol>
                ))}
        </MDBRow>
        <hr style={{
        }}/>
        <p style={{
            // textAlign:"center",
            fontWeight: "700",
            fontSize: "20px"
        }}>Refill Price List:</p>
        <div className="d-flex justify-content-center">
            <RefillList />
        </div>
        <hr style={{
            marginTop: "20px"
        }}/>
        {/* <Accessories /> */}
        {/* <Footer /> */}
    </>
    )
};

export default Products;
